import React from 'react';

const PageNotFound = () => {
    return (
        <div style={{ color: "black", marginTop: "5vh" }}>
            <h1 style={{ color: "white" }}>404 Page not Found</h1>
        </div>
    )
}

export default PageNotFound