import React from 'react';

import './Mailchimp.css'

const MailchimpForm = () => {
    return (
        <form action="https://submit-form.com/SYvpKRTOB">
            <input
                type="hidden"
                name="_redirect"
                value="https://unorthodoxstudio.ca/contact"
            />
            <label for="name" className='header-text'>Name</label>
            <input className='mail-header mail-input' type="text" id="name" name="name" required="" />
            <label for="email" className='header-text'>Email</label>
            <input className='mail-header mail-input' type="email" id="email" name="email" required="" />
            <label for="message"className='header-text'>Message</label>
            <textarea
                className='mail-header mail-input-message'
                id="message"
                name="message"

                required=""
            ></textarea>
            <button className='mail-header mail-button' style={{ width: "100%"}} type="submit">SEND</button>
        </form>
    );
};

export default MailchimpForm;